exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-p-index-js": () => import("./../../../src/pages/p/index.js" /* webpackChunkName: "component---src-pages-p-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-a-cop-who-said-lets-start-a-riot-in-an-instagram-post-has-been-fired-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/a-cop-who-said-lets-start-a-riot-in-an-instagram-post-has-been-fired/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-a-cop-who-said-lets-start-a-riot-in-an-instagram-post-has-been-fired-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-a-lot-of-celebrities-are-being-useless-right-now-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/a-lot-of-celebrities-are-being-useless-right-now/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-a-lot-of-celebrities-are-being-useless-right-now-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-an-18-year-old-said-she-was-raped-while-in-police-custody-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/an-18-year-old-said-she-was-raped-while-in-police-custody/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-an-18-year-old-said-she-was-raped-while-in-police-custody-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-authorities-investigating-the-huxley-stauffer-case-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/authorities-investigating-the-huxley-stauffer-case/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-authorities-investigating-the-huxley-stauffer-case-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-instagram-influencing-has-long-had-a-diversity-problem-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/instagram-influencing-has-long-had-a-diversity-problem/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-instagram-influencing-has-long-had-a-diversity-problem-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-iowa-voted-a-racist-out-of-congress-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/iowa-voted-a-racist-out-of-congress/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-iowa-voted-a-racist-out-of-congress-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-my-first-post-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/my-first-post/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-my-first-post-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-my-second-post-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/my-second-post/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-my-second-post-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-my-third-post-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/my-third-post.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-my-third-post-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-peaceful-protesters-were-gassed-outside-the-white-house-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/peaceful-protesters-were-gassed-outside-the-white-house/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-peaceful-protesters-were-gassed-outside-the-white-house-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-police-arrested-more-than-11000-people-at-protests-across-the-us-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/police-arrested-more-than-11000-people-at-protests-across-the-us/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-police-arrested-more-than-11000-people-at-protests-across-the-us-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-theres-one-big-reason-why-police-brutality-is-so-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/theres-one-big-reason-why-police-brutality-is-so/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-theres-one-big-reason-why-police-brutality-is-so-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-we-still-dont-know-exactly-how-the-stauffers-placed-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/we-still-dont-know-exactly-how-the-stauffers-placed/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-we-still-dont-know-exactly-how-the-stauffers-placed-index-mdx" */),
  "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-were-keeping-a-running-list-of-hoaxes-and-misleading-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/home/karun/projects/karunsiri.dev/posts/were-keeping-a-running-list-of-hoaxes-and-misleading/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-home-karun-projects-karunsiri-dev-posts-were-keeping-a-running-list-of-hoaxes-and-misleading-index-mdx" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "slice---src-components-header-header-js": () => import("./../../../src/components/Header/Header.js" /* webpackChunkName: "slice---src-components-header-header-js" */)
}

