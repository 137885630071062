import React from "react"
import { GlobalContextProvider } from "./src/components/GlobalContextProvider"

import "@fontsource/open-sans"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"
import "@fontsource/source-code-pro/700.css"
import './src/styles/global.css'

// To wrap Gatsby root element inside global-state-aware context
export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>
      {element}
    </GlobalContextProvider>
  )
}
